import * as Sentry from '@sentry/sveltekit';

if (import.meta.env.MODE === 'production') {
	Sentry.init({
		dsn: 'https://6e9c4617dcc13816be733981809ccedc@o4506689353547776.ingest.sentry.io/4506701889667072',
		tracesSampleRate: 1.0,

		// Was 0.1 >> This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 1.0,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [
			Sentry.replayIntegration({
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false,
			}),
		],
	});

	// If you have a custom error handler, pass it to `handleErrorWithSentry`
	// export const handleError = handleErrorWithSentry();
}
